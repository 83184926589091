import {Route,Routes} from 'react-router-dom'
import Register from  './pages/Registrations'


function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Register />}></Route>      
    </Routes>
    </>
  );
}

export default App;