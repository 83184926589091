
import React from 'react'
import '../pages/Register.css'
import pic from "../assets/20220625_032422.png";

import { useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
// import {
// 	getAuth,
// 	RecaptchaVerifier,
// 	signInWithPhoneNumber,
// 	PhoneAuthProvider,
// 	signInWithCredential,
// } from "firebase/auth";
// import app from "./firebase.config";


function Registrations() {

	const [contacts, setContacts] = useState({

	})


	const [formErrors, setFormErrors] = useState({});


	const handleInputChange = (e) => {
		const { name, value } = e.target
		setContacts({ ...contacts, [name]: value })
	}
	const validate = (values) => {

		var error = {}
		var regName = /^[a-zA-Z]+$/;
		var regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		var regPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
		var regPin = /^\d{6}$/
		var regpass = /^\d{4}$/

		if (!values.FirstName) {
			error.FirstName = "First Name can not be empty"
		}
		if (!values.LastName) {
			error.Lastname = "Last Name can not be empty"
		}


		if (!values.Email) {
			error.Email = "Email can not be empty"
		}
		else if (!regEmail.test(values.Email)) {
			error.Email = "Please enter valid email"
		}

		if (!values.Phone) {
			error.Phone = "Phone Number can not be empty"
		}
		else if (!regPhone.test(values.Phone)) {
			error.Phone = "Please enter valid phone number"
		}


		if (!values.Course) {
			error.Course = "Please Choose an option"
		}

		if (!values.Branch) {
			error.Branch = "Please Choose an option"
		}


		// if (!values.Internship) {
		// 	error.Internship = "Please Choose an option"
		// }

		if (!values.Gender) {
			error.Gender = "Choose an option"
		}

		return error
	}





	const handleSubmit = (e) => {
		e.preventDefault();
		const errors = validate(contacts);

		if (Object.keys(errors).length === 0) {
			const formDatass = new FormData();
			formDatass.append('FirstName', contacts.FirstName);
			formDatass.append('LastName', contacts.LastName);
			formDatass.append('Email', contacts.Email);
			formDatass.append('Phone', contacts.Phone);
			formDatass.append('Course', contacts.Course);
			formDatass.append('Branch', contacts.Branch);
			// formDatass.append('Internship', contacts.Internship);
			formDatass.append('Gender', contacts.Gender);

			axios
				.post('https://script.google.com/macros/s/AKfycbyVJNh6fht7bY_3tjwKB-5Y34HRMrqYJRzbu0OwgQLUjZuCpxnj_sU3s8YLHX2SFVwZ/exec', formDatass)
				.then((response) => {
					console.log(response);
					setContacts({
						FirstName: '',
						LastName: '',
						Email: '',
						Phone: '',
						Course: '',
						Branch: '',
						// Internship: '',
						Gender: '',
					});
					Swal.fire({
						title: 'Thank You!',
						text: 'Registration completed successfully.',
						icon: 'success',
						background: '#464545',
						color: '#13a273',
						showCancelButton: true,
						confirmButtonClass: 'custom-confirm-button',
						cancelButtonClass: 'custom-cancel-button',
						confirmButtonText: 'OK',
						cancelButtonText: 'Cancel',
					});
				})
				.catch((error) => {
					console.error('Error submitting the form:', error);
					Swal.fire({
						title: 'Error!',
						text: 'An error occurred while submitting the form. Please try again later.',
						icon: 'error',
						background: '#464545',
						color: '#13a273',
					});
				});
		} else {
			setFormErrors(errors);
			// Swal.fire({
			// 	title: 'Try Again!',
			// 	icon: 'error',
			// 	background: '#464545',
			// 	color: '#13a273',
			// });
		}
	};




	return (

		<section class="main">
			<ToastContainer />
			<center><span><img src={pic} alt="" height="180px" width="250" style={{ objectFit: "cover", height: '73px' }} /></span></center>

			<div className='form' >
				<div class="main-box">
					<div class="input-box">
						<span class="text">First Name<span style={{ color: "red" }}>*</span></span>
						<input type="text" placeholder="Please enter your First Name" name='FirstName' value={contacts.FirstName} onChange={handleInputChange} onClick={() => { setFormErrors({ ...formErrors, FirstName: " " }) }} />

						<span style={{ color: formErrors.FirstName ? "red" : "" }} >{formErrors.FirstName} </span>
					</div>
					<div class="input-box">
						<span class="text">Last Name<span style={{ color: "red" }}>*</span></span>
						<input type="text" placeholder="Please enter your Last Name" name='LastName' value={contacts.LastName} onChange={handleInputChange} onClick={() => { setFormErrors({ ...formErrors, LastName: " " }) }} />

						<span style={{ color: formErrors.LastName ? "red" : "" }} >{formErrors.LastName} </span>
					</div>

					<div class="container">
						<input type="radio" name="Gender" id="circle-1" onClick={() => { setFormErrors({ ...formErrors, Gender: " " }) }} onChange={handleInputChange} value="Male" checked={contacts?.Gender === 'Male'} />
						<input type="radio" name="Gender" id="circle-2" onClick={() => { setFormErrors({ ...formErrors, Gender: " " }) }} onChange={handleInputChange} value="Female" checked={contacts?.Gender === 'Female'} />
						<span class="gender">Gender<span style={{ color: "red" }}>*</span></span>
						<div class="category">
							<label for="circle-1">
								<span class="circle one"></span>
								<span class="male">Male</span>&nbsp;&nbsp;&nbsp;
							</label>
							<label for="circle-2">
								<span class="circle two"></span>
								<span class="male">Female</span>
							</label>
						</div>
						<span style={{ color: formErrors.Gender ? "red" : "", marginTop: '-20px', position: 'absolute' }}> {formErrors.Gender}</span>
					</div>



					<div class="input-box">
						<span class="text">Phone Number(WhatsApp)<span style={{ color: "red" }}>*</span></span>
						<input type="text" placeholder="Phone Number" name='Phone' value={contacts.Phone} onClick={() => { setFormErrors({ ...formErrors, Phone: " " }) }} onChange={handleInputChange} />
						<span style={{ color: formErrors.Phone ? "red" : "" }}> {formErrors.Phone}</span>


					</div>




					<div class="input-box">
						<span class="text">Email<span style={{ color: "red" }}>*</span></span>
						<input type="email" placeholder="Email" name='Email' value={contacts.Email} onClick={() => { setFormErrors({ ...formErrors, Email: " " }) }} onChange={handleInputChange} />
						<span style={{ color: formErrors.Email ? "red" : "" }}> {formErrors.Email}</span>
					</div>


					<div class="input-box">
						<span class="text">Course<span style={{ color: "red" }}>*</span></span>
						<select name="Course" id="sel" value={contacts.Course} onClick={() => { setFormErrors({ ...formErrors, Course: " " }) }} onChange={handleInputChange} >
							<option className='option' value="">Choose Course</option>
							<option className='option' value="BA">BA</option>
							<option className='option' value="BCA">BCA</option>
							<option className='option' value="BE">BE</option>
							<option className='option' value="Bsc">Bsc</option>
							<option className='option' value="B Tech">B Tech</option>
							<option className='option' value="DIPLOMA">DIPLOMA</option>
							<option className='option' value="MA">MA</option>
							<option className='option' value="MCA">MCA</option>
							<option className='option' value="ME">ME</option>
							<option className='option' value="Msc">Msc</option>
							<option className='option' value="M Tech">M Tech</option>
							<option className='option' value="Others">OTHERS</option>

						</select>
						<span style={{ color: formErrors.Course ? "red" : "" }}> {formErrors.Course}</span>
					</div>

					<div class="input-box">
						<span class="text">Browse By Stream<span style={{ color: "red" }}>*</span></span>
						<select name="Branch" id="sel" value={contacts.Branch} onChange={handleInputChange} onClick={() => { setFormErrors({ ...formErrors, Branch: " " }) }}>
							<option className='option' value="">Choose Stream</option>
							<option className='option' value="Arts">Arts</option>
							<option className='option' value="Auto Mobile Engineering">Auto Mobile Engineering</option>
							<option className='option' value="Biomedical">Biomedical</option>
							<option className='option' value="Biotechnology">Biotechnology</option>
							<option className='option' value="Chemistry">Chemistry</option>
							<option className='option' value="Civil">Civil</option>
							<option className='option' value="Computer Applications">Computer Applications</option>
							<option className='option' value="Computer Science">Computer Science</option>
							<option className='option' value="Electrical & Electronics">Electrical & Electronics</option>
							<option className='option' value="Electronics">Electronics</option>
							<option className='option' value="Electronics & Communications">Electronics & Communications</option>
							<option className='option' value="English">English</option>
							<option className='option' value="Finance">Finance</option>
							<option className='option' value="Humanities & Social Science">Humanities & Social Science</option>


						</select>
						<span style={{ color: formErrors.Branch ? "red" : "" }}> {formErrors.Branch}</span>
					</div>




			




				</div>
				<div class="btn">
					<input type="button" value="Register" onClick={handleSubmit} />

				</div>
			</div>
		</section>
	)
}

export default Registrations




// <div class="input-box">
// <span class="text">Domain<span style={{ color: "red" }}>*</span></span>
// <select name="Internship" id="sel" value={contacts.Internship} onChange={handleInputChange} onClick={() => { setFormErrors({ ...formErrors, Internship: " " }) }}>
// 	<option className='option' value="">Choose Option</option>
// 	<option className='option' value="Data Science & Data Analytics">Data Science & Data Analytics</option>
// 	<option className='option' value="Full Stack Web Development - MERN">Full Stack Web Development - MERN</option>
// 	<option className='option' value="Full Stack Web Development - MEAN">Full Stack Web Development - MEAN</option>
// 	<option className='option' value="Full Stack Web Development - PYTHON">Full Stack Web Development - PYTHON</option>
// 	<option className='option' value="Full Stack Web Development - JAVA">Full Stack Web Development - JAVA</option>
// 	<option className='option' value="Full Stack Web Development - PHP">Full Stack Web Development - PHP</option>
// 	<option className='option' value="Flutter Mobile Application Development">Flutter Mobile Application Development</option>
// 	<option className='option' value="React JS">React JS</option>
// 	<option className='option' value="Angular">Angular</option>
// 	<option className='option' value="React Native Mobile Application Development">React Native Mobile Application Development</option>
// 	<option className='option' value="ASP.NET">ASP.NET</option>
// 	<option className='option' value="Block Chain">Block Chain</option>
// 	<option className='option' value="Vue JS">Vue JS</option>
// 	<option className='option' value="Artificial Intelligence">Artificial Intelligence</option>
// 	<option className='option' value="Machine Learning">Machine Learning</option>
// 	<option className='option' value="Power BI">Power BI</option>
// 	<option className='option' value="Embedded Systems % IOT">Embedded Systems & IOT</option>
// 	<option className='option' value="Robotics">Robotics</option>
// 	<option className='option' value="Software Testing">Software Testing</option>


// </select>
// <span style={{ color: formErrors.Internship ? "red" : "" }}> {formErrors.Internship}</span>
// </div>